
import { defineComponent } from 'vue';
import { IonBackdrop, IonButton, IonIcon } from '@ionic/vue';
import MessagesKeyboard from '@/components/Messages/MessagesKeyboard.vue';
import MessagesStream from '@/components/Messages/MessagesStream.vue';
import { hqMessages } from '@/services/messages';

const focusableQueryString =
    '[tabindex]:not([tabindex^="-"]), input, textarea, button, select, .ion-focusable';

export default defineComponent({
    name: 'MessagesHq',

    components: {
        IonBackdrop,
        IonButton,
        IonIcon,
        MessagesKeyboard,
        MessagesStream,
    },

    props: {
        title: {
            type: String,
            default: 'Notifications',
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        isOpen(value) {
            if (value) {
                this.focusFirstDescendant();
                // wait 5 seconds to mark convo as read
                setTimeout(() => this.markNotificationRead(), 5000);
            }
        },
    },

    methods: {
        closeModal() {
            this.$emit('notifications-toggle', false);
        },
        focusFirstDescendant() {
            const wrapper = this.$refs.modalWrapper as HTMLElement;
            let firstInput = wrapper.querySelector(
                focusableQueryString
            ) as HTMLElement | null;

            const shadowRoot = firstInput && firstInput.shadowRoot;
            if (shadowRoot) {
                firstInput = shadowRoot.querySelector(
                    'input, textarea, button, select'
                );
            }

            if (firstInput) {
                firstInput.focus();
            } else {
                // Focus overlay instead of letting focus escape
                const overlay = this.$refs.overlay as HTMLElement;
                overlay.focus();
            }
        },
        focusLastDescendant() {
            const wrapper = this.$refs.modalWrapper as HTMLElement;
            const inputs = Array.from(
                wrapper.querySelectorAll(focusableQueryString)
            ) as HTMLElement[];
            let lastInput =
                inputs.length > 0 ? inputs[inputs.length - 1] : null;

            const shadowRoot = lastInput && lastInput.shadowRoot;
            if (shadowRoot) {
                lastInput = shadowRoot.querySelector(
                    'input, textarea, button, select'
                );
            }

            if (lastInput) {
                lastInput.focus();
            } else {
                // Focus overlay instead of letting focus escape
                const overlay = this.$refs.overlay as HTMLElement;
                overlay.focus();
            }
        },
    },

    setup() {
        const msgService = {...hqMessages()};
        return msgService;
    },
});
