
import { defineComponent, PropType } from 'vue';
import parseDate from 'date-fns/parseJSON';
import { ChatMessageI } from '@/services/messages/MessagesService';

export default defineComponent({
    name: 'MessagesStreamMessage',

    props: {
        message: {
            type: Object as PropType<ChatMessageI>,
            required: true,
        },
        name: {
            type: String,
            default: '',
        },
        showName: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        initial(): string {
            return this.name.slice(0, 1);
        },
        timestamp(): string {
            return parseDate(
                this.message.attributes?.createdAt ?? ''
            ).toLocaleString();
        },
    },
});
