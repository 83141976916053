
import { defineComponent} from 'vue';
import { IonList, IonItem, IonItemDivider } from '@ionic/vue';
import { GroupedChatMessagesI } from '@/services/messages/MessagesService';
import MessagesStreamMessage from '@/components/Messages/MessagesStreamMessage.vue';

export default defineComponent({
    name: 'MessagesStream',

    components: {
        MessagesStreamMessage,
        IonItem,
        IonItemDivider,
        IonList,
    },

    props: {
        messageGroups: {
            type: Object,
            default() {
                return [] as GroupedChatMessagesI[];
            },
        },
        firstNewGroup: {
            type: Object,
        },
        inName: String,
        outName: String,
        showDates: {
            type: Boolean,
            default: true,
        },
        showStoreName: {
            type: Boolean,
            default: true,
        },
    },
});
