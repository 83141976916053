
import { defineComponent, ref } from 'vue';
import { IonButton, IonIcon, IonTextarea, IonItem } from '@ionic/vue';
import { ApiService } from '@/services/api';

const client = new ApiService();

export default defineComponent({
    name: 'MessagesKeyboard',
    props: {
        showSuggestions: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        IonButton,
        IonIcon,
        IonTextarea,
        IonItem,
    },

    data() {
        return {
            message: '',
        };
    },

    methods: {
        sendMessage(message: string): void {
            this.$emit('send-message', message);
        },
        sendCustomMessage(): void {
            this.sendMessage(this.message);
            this.message = '';
        },
    },

    setup() {
        const responses = ref([]);
        client.getConfigs().then(
            ({data})=> responses.value = data?.copy_translations['smartpickup.guest_responses']
        );

        return { responses };
    },
});
